import { graphql } from 'gatsby';
import React from 'react';

import '../styles/layout/services.scss';
import {
  Link,
  Trans,
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import MagneticButton from '../components/MagneticButton';
import { ListTLink, animateObjects, newContent } from '../components/Layout';
import SEO from '../components/seo';
import GiveContentTrans from '../components/GiveContentTrans';

import Layout from '../components/Layout';

/* // Create component in same file. We use it just here so keep it in same file. Otherwise export etc,.
function WorkPreview({ work }) {
  return (
    <div>
      <Link to={`/work/${work.slug}`}>
        <h2>
          <span className="mark">{work.title}</span>
        </h2>
      </Link>
     
      <Img fluid={work.image.asset.fluid} alt={work.title} />
    </div>
  );
} */

// markup
// const IndexPage = ({ data }) => {

function AboutPage() {
  const { t } = useTranslation();
  const { languages, originalPath, i18n } = useI18next();

  const langCode = i18n.language;
  return (
    <Layout>
    <main className="services-page outer-grid">
      <GiveContentTrans language="et">
        <SEO
          title="Digiagentuuri Teenused"
          description="Koduleht, UI/UX graafiline disain, logo kujundus või tarkvara arendus. Give teeb ära!"
          image="/pagecover-teenused.jpg"
          structuredData={{
            "@type": "WebPage",
            "name": "Digiagentuuri teenused",
            "description": "Koduleht, UI/UX graafiline disain, logo kujundus või tarkvara arendus. Give teeb ära!",
            "url": "https://give.ee/teenused/", 
            "inLanguage": "et"
          }}
        />
      </GiveContentTrans>
      <GiveContentTrans language="en">
        <SEO
          title="Digital agency services"
          description="Websites, UI/UX design, graphic design, branding and logo creation or web development. Give digital agency makes it happen!"
          image="/pagecover-en-default.jpg"
          structuredData={{
            "@type": "WebPage",
            "name": "Digital agency services",
            "description": "Websites, UI/UX design, graphic design, branding and logo creation or web development. Give digital agency makes it happen!",
            "url": "https://give.ee/en/services/", 
            "inLanguage": "en"
          }}
        />
      </GiveContentTrans>
      <section className="hero">
        <GiveContentTrans language="et">
          <h1>
            Disain ja arendus.
            <strong>Teeme&nbsp;ära!</strong>
          </h1>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h1>
            Digital design and development.
            <strong>We'll get it done!</strong>
          </h1>
        </GiveContentTrans>
      </section>
      <section className="description">
        <div className="page-copy">
          <GiveContentTrans language="et">
            <p>
              Give trumpaladeks on UI/UX disain,
              kodulehe erilahendused ja logode ning firmastiili kujundamine. Disainime ja
              programmeerime. Teeme asjad ära ja arvatavasti vähemalt 2x paremini kui konkurendid. 
             
            </p>
<p className="p-topmargin">Digilahendused mis teenivad raha tagasi. Võta ühendust ja saad teada kuidas seda teeme!</p>

<a href="https://cal.com/tanels/give-free-calls" className="call-cta">
    <MagneticButton
      addionalClasses="cta cta-xl"
      buttonLabel="Broneeri tasuta videokõne"
      magneticDistance="150"
      magneticSpeed="0.4"
    />
  </a>
          </GiveContentTrans>
          <GiveContentTrans language="en">
          <p>
  Our strengths include UI/UX design, custom website solutions, and logo and corporate identity design. We handle both design and programming. We get things done, likely at least twice as well as the competition.
</p>

<p className="p-topmargin">Our work earns the money back fast! Contact us and find out how we do it!</p>

<a href="https://cal.com/tanels/give-free-calls" className="call-cta">
    <MagneticButton
      addionalClasses="cta cta-xl"
      buttonLabel="Book a Call"
      magneticDistance="150"
      magneticSpeed="0.4"
    />
  </a>
          </GiveContentTrans>
        </div>
      </section>

      {/*       <div className="hero-image">
        <StaticImage
          src="../assets/tanel.png"
          alt="Tanel Kaarel Sepp"
          layout="fullWidth"
        />
      </div> */}

      <section className="service-section media-right inner-grid">
        <GiveContentTrans language="et">
          <div className="media">
            <StaticImage
              src="../assets/veeb-thumbnail.jpg"
              alt="Veebilehtede loomine"
              layout="fullWidth"
            />
          </div>

          <div className="block">
            <h2>Kodulehtede tegemine</h2>
            <p>
              Kasutajasõbralik ja isikupärane koduleht on oluline turunduskanal
              igale ettevõttele. Disainime ja arendame moodsaid ning tulemusitoovaid
              veebilehti. Sisuhaldussüsteemidest kasutame peamiselt Webflowd,
              Framerit ja Wordpressi.
            </p>
            <ListTLink
              to="/teenused/kodulehed/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Loe edasi"
                magneticDistance="50"
                magneticSpeed="0.4"
              />
            </ListTLink>
          </div>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <div className="media">
            <StaticImage
              src="../assets/veeb-thumbnail.jpg"
              alt="Veebilehtede loomine"
              layout="fullWidth"
            />
          </div>

          <div className="block">
            <h2>Website creation</h2>
            <p>
              We create user-friendly and personalized websites that are an
              important marketing channel for any business. We design and
              develop websites of varying complexity and mainly use Webflow,
              Framer, and Wordpress as content management systems.
            </p>
            <ListTLink
              to="/services/websites/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Read more"
                magneticDistance="50"
                magneticSpeed="0.4"
              />
            </ListTLink>
          </div>
        </GiveContentTrans>
      </section>

      <section className="service-section media-left inner-grid">
        <GiveContentTrans language="et">
          <div className="media">
            <StaticImage
              src="../assets/UI-UX-disain.jpg"
              alt="UI disaini elemendid"
              layout="fullWidth"
            />
          </div>

          <div className="block">
            <h2>UI/UX disain</h2>
            <p>
              Disainime ning prototüübime kaasaegseid ning kasutajasõbralikke kasutajaliidesed nii veebirakendustele kui digitaalsetele toodetele. 
              Meie UI/UX lahendused näevad head välja ja täidavad püstitatud eesmärke.
            </p>
            <ListTLink
              to="/teenused/ui-ux-disain/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Loe edasi"
                magneticDistance="50"
                magneticSpeed="0.4"
              />
            </ListTLink>
          </div>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <div className="media">
            <StaticImage
              src="../assets/mobiiliappide-disain.jpg"
              alt="Mobiilirakenduste disain"
              layout="fullWidth"
            />
          </div>

          <div className="block">
            <h2>UI/UX design</h2>
            <p>
              We design and prototype modern and user friendly interfaces for webapplications and digital products. Our UI/UX designs are intuitive to use and look great at the same time.
            </p>
            <ListTLink
              to="/services/ui-ux-design/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Read more"
                magneticDistance="50"
                magneticSpeed="0.4"
              />
            </ListTLink>
          </div>
        </GiveContentTrans>
      </section>


      <section className="service-section media-right inner-grid">
        <GiveContentTrans language="et">
          <div className="media">
            <StaticImage
              src="../assets/mobiiliappide-disain.jpg"
              alt="Mobiilirakenduste disain"
              layout="fullWidth"
            />
          </div>

          <div className="block">
            <h2>Mobiiliäppide UI/UX disain</h2>
            <p>
              Disainime intuitiivseid rakendusi ja teeme nii animeeritud kui
              klikitavaid prototüüpe, et saaksite ideed sihtgrupi peal testida.
            </p>
            <ListTLink
              to="/teenused/mobiilirakendused/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Loe edasi"
                magneticDistance="50"
                magneticSpeed="0.4"
              />
            </ListTLink>
          </div>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <div className="media">
            <StaticImage
              src="../assets/mobiiliappide-disain.jpg"
              alt="Mobiilirakenduste disain"
              layout="fullWidth"
            />
          </div>

          <div className="block">
            <h2>Mobile app design</h2>
            <p>
              We design intuitive applications and create both animated and
              clickable prototypes to test your ideas on the target audience.
            </p>
            <ListTLink
              to="/services/mobile-apps/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Read more"
                magneticDistance="50"
                magneticSpeed="0.4"
              />
            </ListTLink>
          </div>
        </GiveContentTrans>
      </section>

      <section className="service-section media-left inner-grid">
        <GiveContentTrans language="et">
          <div className="media">
            <StaticImage
              src="../assets/logoandcvi.jpg"
              alt="Logo ja CVI kujundamine. Turva"
              layout="fullWidth"
            />
          </div>

          <div className="block">
            <h2>Logo ja brändiraamatu disainimine</h2>
            <p>
              Logo ja brändi identiteedi disainimine nullist sajani. Olemasolevaid brände aitame
              kaasajastada läbi oskusliku disainimõtlemise. Kujundame
              logoraamatuid ehk firmastiile.
            </p>
            <ListTLink
              to="/teenused/logo-kujundamine/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Loe edasi"
                magneticDistance="50"
                magneticSpeed="0.4"
              />
            </ListTLink>
          </div>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <div className="media">
            <StaticImage
              src="../assets/logoandcvi.jpg"
              alt="Logo ja CVI kujundamine. Turva"
              layout="fullWidth"
            />
          </div>

          <div className="block">
            <h2>Logo and brand identity design</h2>
            <p>
              We design brands from zero to hundred. For existing brands, we
              help modernize them through skillful design thinking. We also
              create style guides.
            </p>
            <ListTLink
              to="/services/brand-identity/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Read more"
                magneticDistance="50"
                magneticSpeed="0.4"
              />
            </ListTLink>
          </div>
        </GiveContentTrans>
      </section>

      <section className="service-section media-right inner-grid">
        <GiveContentTrans language="et">
          <div className="media">
            <StaticImage
              src="../assets/epoodide-tegemine.jpg"
              alt="E-poodide tegemine"
              layout="fullWidth"
            />
          </div>
          <div className="block">
            <h2>E-poodide loomine</h2>
            <p>
              Teeme e-poodide erilahendusi. Meie loodud e-poed on unikaalse
              kujundusega ja erinevad masstoodangust tänu personaliseeritusele.
              Aitame suurendada müüki ja automatiseerida tööprotsesse.
            </p>
            <ListTLink
              to="/teenused/epood/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Loe edasi"
                magneticDistance="50"
                magneticSpeed="0.4"
              />
            </ListTLink>
          </div>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <div className="media">
            <StaticImage
              src="../assets/epoodide-tegemine.jpg"
              alt="E-poodide tegemine"
              layout="fullWidth"
            />
          </div>
          <div className="block">
            <h2>E-commerce solutions</h2>
            <p>
              We create custom e-commerce solutions. Our designed online stores
              have unique layouts and stand out from mass-produced solutions
              thanks to their personalization. We help increase sales and
              automate work processes.
            </p>
            <ListTLink
              to="/services/ecommerce/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Read more"
                magneticDistance="50"
                magneticSpeed="0.4"
              />
            </ListTLink>
          </div>
        </GiveContentTrans>
      </section>

      <section className="service-section media-left inner-grid">
        <GiveContentTrans language="et">
          <div className="media">
            <StaticImage
              src="../assets/maandumislehed.jpg"
              alt="Landing page disain"
              layout="fullWidth"
            />
          </div>

          <div className="block">
            <h2>Maandumislehtede loomine</h2>
            <p>
              Maandumislehed ehk "landing pages" on veebilehed, mis on kindlale
              eesmärgile fokusseeritud. Aitame teie brändi jaoks need kujundada
              ja liidestada. Mõõdame tulemusi ja teeme AB teste, et tõsta müüki.
            </p>
            <ListTLink
              to="/teenused/maandumislehed/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Loe edasi"
                magneticDistance="50"
                magneticSpeed="0.4"
              />
            </ListTLink>
          </div>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <div className="media">
            <StaticImage
              src="../assets/maandumislehed.jpg"
              alt="Landing page disain"
              layout="fullWidth"
            />
          </div>

          <div className="block">
            <h2>Landing page creation</h2>
            <p>
              Landing pages are web pages focused on a specific goal. We help
              design and develope them for your brand. We measure results and
              perform A/B testing to increase sales.
            </p>
            <ListTLink
              to="/services/landingpages/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Read more"
                magneticDistance="50"
                magneticSpeed="0.4"
              />
            </ListTLink>
          </div>
        </GiveContentTrans>
      </section>

      <section className="service-section media-right inner-grid">
        <GiveContentTrans language="et">
          <div className="media">
            <StaticImage
              src="../assets/uudiskirjad.jpg"
              alt="Uudiskirjade kujundused"
              layout="fullWidth"
            />
          </div>
          <div className="block">
            <h2>Uudiskirjade disain</h2>
            {/*
          <p>
            Disainime ja arendame visuaale uudiskirja kampaaniate jaoks kui ka
            e-kirjade disaini süsteeme automatiseeritud uudiskirjade vooks.
            Leiame lahendused kuidas tehniliselt piiratud e-maili turundus
            maailma tuua vürtsikaid lahendusi.
          </p> */}
            <p>
              Disainime ja arendame visuaale uudiskirja kampaaniate jaoks kui ka
              e-kirjade disaini süsteeme automatiseeritud uudiskirjade saatmise
              jaoks. Leiame lahendused kuidas tehniliselt piiratud e-maili
              turunduse maailma tuua vürtsikaid lahendusi.
            </p>
            <ListTLink
              to="/teenused/uudiskirjad/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Loe edasi"
                magneticDistance="50"
                magneticSpeed="0.4"
              />{' '}
            </ListTLink>
          </div>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <div className="media">
            <StaticImage
              src="../assets/uudiskirjad.jpg"
              alt="Uudiskirjade kujundused"
              layout="fullWidth"
            />
          </div>
          <div className="block">
            <h2>Newsletter Design</h2>

            <p>
              We design and develop visuals for newsletter campaigns and email
              design systems for automated newsletter delivery. We find
              solutions to bring spicy solutions to the technically limited
              world of email marketing.
            </p>
            <ListTLink
              to="/services/newsletters/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Read more"
                magneticDistance="50"
                magneticSpeed="0.4"
              />{' '}
            </ListTLink>
          </div>
        </GiveContentTrans>
      </section>

      <section className="service-section media-left inner-grid">
        <GiveContentTrans language="et">
          <div className="media">
            <StaticImage
              src="../assets/ooh.jpg"
              alt="Graafiline disain"
              layout="fullWidth"
            />
          </div>
          <div className="block">
            <h2>Graafiline disain</h2>
            <p>
              Mõtleme välja ja disainime reklaame. Hästi kujundatud reklaam tõstab brändi väärtust
              ja tuntust. Disainime erinevate meediumite jaoks nii trükiseid kui
              digitaalseid loovlahendusi. Meie tehtud graafiline disain on kasutatud nii liinibusside,
              juunior WRC autot, kui ka traditsioonilis pindade katmiseks.
            </p>

            <ListTLink
              to="/teenused/graafiline-disain/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Loe edasi"
                magneticDistance="50"
                magneticSpeed="0.4"
              />
            </ListTLink>
          </div>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <div className="media">
            <StaticImage
              src="../assets/ooh.jpg"
              alt="Graafiline disain"
              layout="fullWidth"
            />
          </div>
          <div className="block">
            <h2>Graphic Design and (outdoor) advertising</h2>
            <p>
              We create and implement. Well-designed advertising increases the
              value and recognition of a brand. We design creative solutions for
              different media, both print and digital. We have designed
              everything from a bus, a junior WRC car, an electric car charger,
              and various information boards.
            </p>

            <ListTLink
              to="/services/graphic-design/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Read more"
                magneticDistance="50"
                magneticSpeed="0.4"
              />
            </ListTLink>
          </div>
        </GiveContentTrans>
      </section>

      <section className="service-section media-right inner-grid">
        <GiveContentTrans language="et">
          <div className="media">
            <StaticImage
              src="../assets/html5-bannerid.jpg"
              alt="Html5 bannerid"
              layout="fullWidth"
            />
          </div>
          <div className="block">
            <h2>Bännerreklaam</h2>
            <p>
              Animeeritud HTML5 ja staatilised bännerid kõikidesse sotsiaalmeediakanalitesse. Viime teie
              ettevõtte igale ekraanile ja paneme kasutajad klikkima. Oskuslikult
              disainitud veebibännerite kampaaniad toovad tulemusi.
            </p>
            <ListTLink
              to="/teenused/html5bannerid/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Loe edasi"
                magneticDistance="50"
                magneticSpeed="0.4"
              />
            </ListTLink>
          </div>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <div className="media">
            <StaticImage
              src="../assets/html5-bannerid.jpg"
              alt="Html5 bannerid"
              layout="fullWidth"
            />
          </div>
          <div className="block">
            <h2>Web Banners</h2>
            <p>
              Animated HTML5 and static banners. For Facebook, Instagram,
              LinkedIn, and other social media channels. We bring your company
              to every screen and make it noticeable. Skillfully designed
              campaigns bring results.
            </p>
            <ListTLink
              to="/services/html5banners/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Read more"
                magneticDistance="50"
                magneticSpeed="0.4"
              />
            </ListTLink>
          </div>
        </GiveContentTrans>
      </section>

      <section className="service-section media-left inner-grid">
        <GiveContentTrans language="et">
          <div className="media">
            <StaticImage
              src="../assets/packaging.jpg"
              alt="HotSpot kohvitopsi disain"
              layout="fullWidth"
            />
          </div>
          <div className="block">
            <h2>Pakendite disain</h2>
            <p>
              Hea pakend räägib tootest enne, kui seda kasutada jõutakse. Kui
              avaneb võimalus, siis vahelduseks hea meelega eksperimenteerime ka
              pakendite kujundamisega.
            </p>
          </div>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <div className="media">
            <StaticImage
              src="../assets/packaging.jpg"
              alt="HotSpot kohvitopsi disain"
              layout="fullWidth"
            />
          </div>
          <div className="block">
            <h2>Packaging Design</h2>
            <p>
              Good packaging speaks about the product before it is used. If the
              opportunity arises, we are happy to experiment with packaging
              design.
            </p>
          </div>
        </GiveContentTrans>
      </section>
    </main>
    </Layout>
 );
}

export default AboutPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
